































































































.selectemployee {
  background-color: #f6f7f9;
}

.el-container {
  width: 100%;
  display: block;
}

.el-main {
  min-height: 100vh;
  padding: 0;
}

.snackbar {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 15px;
  border: 1px solid #e3e5e5;
  border-radius: 8px;
  z-index: 999;
}
